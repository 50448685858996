import React from 'react';

function ContactUs() {
    return (
        <div className="contact-us">
            <form>
                {/* Your form fields here */}
                <input type="text" placeholder="Your Name" />
                <input type="email" placeholder="Your Email" />
                <textarea placeholder="Your Message"></textarea>
                <button type="submit">Send</button>
            </form>
        </div>
    );
}

export default ContactUs;