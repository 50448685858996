// NewestProducts.js
import React from 'react';

function NewestProducts() {
  // You can fetch and map your actual product data here

  const products = [
    {
      id: 1,
      name: 'Product 1',
      description: 'Description for Product 1',
    },
    {
      id: 2,
      name: 'Product 2',
      description: 'Description for Product 2',
    },
    // Add more products here
  ];

  return (
    <section className="newest-products">
      <h2>Newest Products</h2>
      <div className="product-list">
        {products.map((product) => (
          <div key={product.id} className="product">
            <h3>{product.name}</h3>
            <p>{product.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default NewestProducts;
