// HeroSection.js
import React from 'react';

function HeroSection() {
  return (
    <section className="hero">
      <div className="hero-content">
        <h1>Welcome to Our Store</h1>
        <p>Discover the latest products and more.</p>
        <button>Shop Now</button>
      </div>
    </section>
  );
}

export default HeroSection;
