import React from 'react';
import './App.css'; // You can add global styles here
import Header from './Header';
import Footer from './Footer';
import HeroSection from './HeroSection';
import NewestProducts from './NewestProducts';
import ContactUs from './ContactUs';

function App() {
  return (
    <div className="App">
      <Header />
      <HeroSection />
      <NewestProducts />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
